
.icon-large > img{
    max-width: 125px;
    height: auto;
}

.table td,
.table th {
    padding: .25rem .5rem;
    font-size: 0.75rem;
}


.api-response {
    font-size: 0.75rem;
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}